import React from "react";
import ButtonsBottom from "../components/ButtonsBottom";
import DynamicText from "../components/DynamicText";
import Navigation from "../components/Navigation";
import Social from "../components/Social";
import { motion } from "framer-motion";

const Home = () => {

  const variants = {
    initial: {
      opacity: 0,
      transition: { duration: 0.5 },
      x: 100,
    },
    visible: { 
      opacity: 1,
      x: 0,
    },
    exit: {
      opacity: 0,
      transition: { duration: 0.3 },
      x: -100,
    }
  };

  const transition = {
    ease: [0.03, 0.87, 0.73, 0.9],
    duration: 0.6,
  }


  return (
    <div>
    <motion.div
        className="home"
        initial="initial"
        animate="visible"
        exit="exit"
        variants={variants}
        transition={transition}
      >
        <Navigation />
        <Social />
        <div className="home-main">
          <div className="main-content">
            <h1>Cyril GUERRIERO</h1>
            <h2>
              <DynamicText />
            </h2>
            <p>Actuellement à la recherche de missions free-lance ou d'un CDI en agence de communication </p>
          </div>
        </div>
        <ButtonsBottom right={"/competences"} />
      </motion.div>
    </div>
  );
};

export default Home;
