export const projectsData = [
  {
    id: 1,
    title: "DNG-Concept",
    date: "En cours",
    languages: ["Wordpress", "Divi", "CSS"],
    infos:
      "Site pour une petite entreprise spécialisée dans la rénovation de salle de bain",
    img: "./assets/img/dng.jpg",
    link: "",
    styles: "none",
  },
  {
    id: 2,
    title: "L'atelier de Clarisse",
    date: "En cours",
    languages: ["Wordpress", "Divi", "CSS"],
    infos:
      "Site d'achat et de location de décorations de mariage",
    img: "./assets/img/mariage.jpg",
    link: "http://www.google.com",
    styles: "none",
  },
  {
    id: 3,
    title: "Carte French Eyes",
    date: "Juin 2021",
    languages: ["Canvas", "Photoshop", "Wix"],
    infos:
      "Réalisation du design de la carte d'un restaurant parisien. Intégration sur un site internet et création d'un QR code pour y accéder depuis un smartphone",
    img: "./assets/img/french.jpg",
    link: "https://frencheyes75.wixsite.com/cartes",
  },
  {
    id: 4,
    title: "Creative Dev",
    date: "Juillet 2020",
    languages: ["Vue", "Php"],
    infos:
      "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quas cumque labore suscipit, pariatur laboriosam autem omnis saepe quisquam enim iste.",
    img: "./assets/img/projet-4.jpg",
    link: "http://www.google.com",
  },
  {
    id: 5,
    title: "Monsieur Gustave",
    date : "Décembre 2021",
    languages : ["React", "SASS", "Javascript"],
    infos : "Réalisation d'une landing page pour une nouvelle agence de communication digitale",
    img : "./assets/img/monsieur.png",
    link : "https://monsieurgustave.fr",
  }
];
