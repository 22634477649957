import React from "react";
import ButtonsBottom from "../components/ButtonsBottom";
import ContactForm from "../components/ContactForm";
import Navigation from "../components/Navigation";
import Social from "../components/Social";
import { motion } from "framer-motion";

const Contact = () => {

  const variants = {
    initial: {
      opacity: 0,
      transition: { duration: 0.5 },
      x: 0,
    },
    visible: {
      opacity: 1,
      x: 0,
    },
    exit: {
      opacity: 0,
      transition: { duration: 0.3 },
      x: 300,
    },
  };

  const transition = {
    ease: [0.03, 0.87, 0.73, 0.9],
    duration: 0.6,
  }

  return (
    <motion.div 
    className="contact"
    initial="exit"
        animate="visible"
        exit="exit"
        variants={variants}
        transition={transition}
    >
      <Navigation />
      <ContactForm />
      <div className="contact-infos">
        <div className="address">
          <div className="content">
            <h4>Adresse</h4>
            <p> 6 place des charrons </p>
            <p> 57000 Metz</p>
          </div>
        </div>
        <div className="phone">
          <div className="content">
            <h4>Téléphone</h4>
            <p> 06.68.57.40.87 </p>
          </div>
        </div>
        <div className="email">
          <div className="content">
            <h4>
              Email
            </h4>
            <p>
              cyril.guerriero@sfr.fr
            </p>
          </div>
        </div>
        <Social />
        <div className="credits">
          <span>
           <i className="far fa-copyright"> Cyril Guerriero 2021 </i> 
           </span>
        </div>
      </div>
      <ButtonsBottom left={"/projets"} />
    </motion.div>
  );
};

export default Contact;
