import React from "react";
import Hobbies from "../components/Hobbies";
import Languages from "../components/Languages";
import Navigation from "../components/Navigation";
import OtherSkills from "../components/OtherSkills";
import ButtonsBottom from "../components/ButtonsBottom";
import { motion } from "framer-motion";

const Skills = () => {
  const variants = {
    initial: {
      opacity: 0,
      transition: { duration: 0.5 },
      x: 1000,
    },
    visible: {
      opacity: 1,
      x: 0,
    },
    exit: {
      opacity: 0,
      transition: { duration: 0.3 },
      x: -1000,
    },
  };

  const transition = {
    ease: [0.03, 0.87, 0.73, 0.9],
    duration: 0.60,
  };

  return (
      <motion.div
        className="skills"
        initial="initial"
        animate="visible"
        exit="exit"
        variants={variants}
        transition={transition}
      >
        <Navigation />
        <div className="skills-content">
          <Languages />
          <OtherSkills />
          <Hobbies />
        </div>
        <ButtonsBottom left={"/"} right={"/experiences"} />
      </motion.div>
  );
};

export default Skills;
