import React from "react";

const Hobbies = () => {
  return (
    <div className="hobbies">
      <h3>Intérets</h3>
      <ul>
        <li className="hobby">
          <i className="fas fa-futbol"></i>
          <span>Sports</span>
        </li>
        <li className="hobby">
          <i className="fas fa-book"></i>
          <span>Lecture</span>
        </li>
        <li className="hobby">
          <i className="fas fa-video"></i>
          <span>Cinéma</span>
        </li>
        <li className="hobby">
          <i className="fas fa-gamepad"></i>
          <span>Jeux Vidéos</span>
        </li>
        <li className="hobby">
          <i className="fas fa-music"></i>
          <span>Musique</span>
        </li>
      </ul>
    </div>
  );
};

export default Hobbies;
