import React from "react";
import Carousel from "react-elastic-carousel";
import ButtonsBottom from "../components/ButtonsBottom";
import Navigation from "../components/Navigation";
import Project from "../components/Project";
import { motion } from "framer-motion";

const Projects = () => {
  let left = Math.floor(Math.random() * 200 + 1000) + "px";
  let top = Math.floor(Math.random() * 200 + 350) + "px";
  let size = "scale(" + (Math.random() + 0.7) + ")";

  const variants = {
    initial: {
      opacity: 0,
      transition: { duration: 0.5 },
      x: 200,
    },
    visible: {
      opacity: 1,
      x: 0,
    },
    exit: {
      opacity: 0.4,
      transition: { duration: 0.35 },
      x: -800,
    },
  };



  const transition = {
    ease: [0.03, 0.87, 0.73, 0.9],
    duration: 0.6,
  };

  return (
        
      <motion.div 
      className="project"
      initial="initial"
      animate="visible"
      exit="exit"
      variants={variants}
      transition={transition}>
        <Navigation />
        <Carousel className="carou" itemsToShow={1}>
          <Project projectNumber={4} />
          <Project projectNumber={0} />
          <Project projectNumber={1} />
          <Project projectNumber={2} />
          
        </Carousel>
        <span
          className="random-circle"
          style={{ left, top, transform: size }}
        ></span>
        <ButtonsBottom left={"/experiences"} right={"/contact"} />
      </motion.div>
  );
};

export default Projects;
