import React from "react";

const Experiences = () => {
  return (
    <div className="experience-content">
      <div className="experiences">
        <h3>Experiences</h3>
        <div className="exp1">
          <h4>Développeur web - Indépendant</h4>
          <h5>Actuellement</h5>
          <ul>
          <li>Réalisation de plusieurs projets de sites internet </li>
          <li>Elaboration de devis  et factures</li>
          </ul>
        </div>
        <div className="exp2">
          <h4>Déveoppeur web - Web Idea</h4>
          <h5>Novembre 2019 - Janvier 2020</h5>
          <ul> 
            <li>Développement de sites internet </li>
            <li>Création de templates de bannières pour des sites wordpress </li>
            <li>Suivi et maintenance de sites déjà existants</li>
            </ul>
        </div>
      </div>
      <div className="diplome">
        <h3>Diplomes </h3>
        <div className="dip1">
            <h4>Formation Développeur Web - Cefii Angers</h4>
            <ul>
              <li>Développement Front-end</li>
              <li>Développement Back-end</li>
              <li>Développement Mobile</li>
              </ul>
            <h5>Obtention en 2021</h5>
            <h4>BTS Négociation et Relation Client - Metz</h4> 
            <h5>Obtention en 2013</h5>
        </div>

      </div>
    </div>
  );
};

export default Experiences;
