import React, { useEffect } from "react";
import {
  Switch,
  Route,
  useLocation,
  useHistory,
  Redirect,
} from "react-router-dom";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import Projects from "./pages/Projects";
import { AnimatePresence } from "framer-motion";
import Skills from "./pages/Skills";
import Experience from "./pages/Experience";

const App = () => {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const handleScrollToElement = (e) => {
      const url = window.location.origin + "/#/";

      const wheelRouter = (after, before) => {
        if (e.wheelDeltaY < 0) {
          setTimeout(() => {
            history.push(after);
          }, 500);
        } else if (e.wheelDeltaY > 0) {
          setTimeout(() => {
            history.push(before);
          }, 500);
        }
      };

      switch (window.location.href.toString()) {
        case url + "":
          if (e.wheelDeltaY < 0) {
            setTimeout(() => {
              history.push("competences");
            }, 500);
          }
          break;

        case url + "competences":
          wheelRouter("experiences", "");
          break;
        case url + "experiences":
          wheelRouter("projets", "competences");
          break;
        case url + "projets":
          wheelRouter("contact", "experiences");
          break;
        case url + "contact":
          if (e.wheelDeltaY > 0) {
            setTimeout(() => {
              history.push("projets");
            }, 500);
          }
          break;
        default:
          console.log("nothing");
      }
    };

    window.addEventListener("wheel", handleScrollToElement);
  }, [history]);
  return (
    <AnimatePresence>
      
      <Switch location={location} key={location.pathname}>
        <Route exact path="/" component={Home} />
        <Route exact path="/projets" component={Projects} />
        <Route exact path="/competences" component={Skills} />
        <Route exact path="/experiences" component={Experience} />
        <Route exact path="/contact" component={Contact} />
        <Redirect to="/"></Redirect>
      </Switch>
    </AnimatePresence>
  );
};

export default App;
