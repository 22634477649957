import ProgressBar from "../components/ProgressBar";
import React, { Component } from "react";

export default class Languages extends Component {
  state = {
    languages: [
      { id: 1, value: "HTML", xp: 80 },
      { id: 2, value: "CSS", xp: 85 },
      { id: 3, value: "Javascript", xp: 75 },
      { id: 4, value: "PHP", xp: 70 },
    ],
    frameworks: [
      { id: 1, value: "React", xp: 65 },
      { id: 2, value: "Bootstrap", xp: 80 },
      { id: 3, value: "SASS", xp: 65 },
      { id: 4, value: "Wordpress", xp: 87 },
    ],
  };

  render() {
    let { languages, frameworks } = this.state;

    return (
      <div className="languagesFrameworks">
        <div className="languagesDisplay">
        <ProgressBar
          languages={languages}
          title="Languages"
        />
        </div>

        <div className="frameworkDisplay">
        <ProgressBar 
        languages={frameworks}
        title="Frameworks & CMS"/>
        </div>
      </div>
    );
  }
}
