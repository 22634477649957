import React from "react";
import { NavLink } from "react-router-dom";
const Navigation = () => {
  return (
    <div className="navigation">
      <ul>
        <NavLink to="/" exact className="hover" activeClassName="nav-active">
          <li>Accueil</li>
        </NavLink>
        <NavLink to="/competences" exact activeClassName="nav-active">
          <li>Compétences</li>
        </NavLink>
        <NavLink to="/experiences" exact activeClassName="nav-active">
          <li>Expériences</li>
        </NavLink>
        <NavLink to="/projets" exact activeClassName="nav-active">
          <li>Portfolio</li>
        </NavLink>

        <NavLink
          to="/contact"
          exact
          className="hover"
          activeClassName="nav-active"
        >
          <li>Contact</li>
        </NavLink>
      </ul>
    </div>
  );
};

export default Navigation;
