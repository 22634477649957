import React from "react";
import ButtonsBottom from "../components/ButtonsBottom";
import Navigation from "../components/Navigation";
import Experiences from "../components/Experiences";
import { motion } from "framer-motion";

const Experience = () => {
  const variants = {
    initial: {
      opacity: 0,
      transition: { duration: 0.5 },
      x: 1000,
    },
    visible: {
      opacity: 1,
      x: 0,
    },
    exit: {
      opacity: 0,
      transition: { duration: 0.3 },
      x: -1000,
    },
  };

  const transition = {
    ease: [0.03, 0.87, 0.73, 0.9],
    duration: 0.6,
  };
  return (

      <motion.div
        className="experience"
        initial="initial"
        animate="visible"
        exit="exit"
        variants={variants}
        transition={transition}
      >
        <Navigation />
        <Experiences />
        <ButtonsBottom right={"/projets"} left={"/competences"} />
      </motion.div>

  );
};

export default Experience;
